/* src/pages/Research.css */
.research-container {
    padding: 20px;
    max-width: 55%; /* Adjusted for left alignment */
    margin: 0 ; /* Center the container */
  }
  
  h1 {
    margin-bottom: 1rem;
  }
  
  .sub-tab-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .sub-tab-buttons button {
    padding: 0.5rem 1rem;
    border: none;
    background-color: #eeeeee;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .sub-tab-buttons button.active {
    background-color: #007bff;
    color: white;
  }
  
  .sub-tab-content {
    margin-top: 1rem;
  }
  
  .research-item {
    margin-bottom: 1rem;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  /* Supervisors styling */
.supervisors {
  margin-top: 0.5rem;
  color: #4a5568;
}

.supervisor-link {
  color: #2b6cb0;
  text-decoration: none;
  transition: color 0.3s ease;
}

.supervisor-link:hover {
  color: #1a4a8a;
  text-decoration: underline;
}

