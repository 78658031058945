/* Now.css */
.now-page {
    max-width: 680px;
    margin: 0 auto;
    padding: 2rem 1rem;
    line-height: 1.6;
    color: #333;
  }
  
  .now-page h1 {
    font-size: 2.0rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
    color: #1a1a1a;
  }
  
  .now-page h2 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    font-weight: 400;
    color: #666;
  }
  
  .now-page h3 {
    font-size: 1.1rem;
    margin: 1.5rem 0 1rem;
    font-weight: 500;
    color: #444;
  }
  
  .now-list {
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .now-list li {
    margin-bottom: 0.75rem;
    line-height: 1.5;
  }
  
  .now-list em {
    font-style: italic;
    color: #d97706;
  }
  
  /* Link styling */
  a {
    color: #2563eb;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border-color 0.2s;
  }
  
  a:hover {
    border-bottom-color: currentColor;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .now-page {
      padding: 1.5rem;
    }
    
    .now-page h1 {
      font-size: 2rem;
    }
  }