/* About.css */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.profile-container {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 2rem;
}

.profile-sidebar {
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
}

/* In your About.css */
.profile-image {
  /* Existing styles */
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  
  /* New adjustments */
  object-position: 50% 30%; /* Adjust vertical position */
  transform: scale(0.9); /* Zoom out slightly */
  margin: -10px; /* Compensate for zoom-out */
}

.profile-info h1 {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.profile-info h2 {
  font-size: 1.1rem;
  color: #4a5568;
  margin-bottom: 1rem;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;
}

.social-icon {
  font-size: 1.8rem;
  color: #4a5568;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #2b6cb0;
}

.profile-content {
  padding: 0 1rem;
}

.bio-section p {
  font-size: 1.1rem;
  line-height: 1.7;
  color: #4a5568;
  margin-bottom: 2rem;
}

.sections-container {
  display: flex;
  gap: 2rem;
}

.interests-section {
  flex: 1;
  min-width: 250px;
}

.education-section {
  flex: 2;
  min-width: 400px;
}

.interests-section h3,
.education-section h3 {
  font-size: 1.4rem;
  color: #2c3e50;
  border-bottom: 2px solid #e2e8f0;
  padding-bottom: 0.5rem;
  margin-bottom: 1.5rem;
}

.interests-section ul {
  list-style: none;
  padding-left: 0;
}

.interests-section li {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: #4a5568;
}

.edu-icon {
  color: #2b6cb0;
  font-size: 1.5rem;
  margin-top: 0.3rem;
}
/* In your About.css */
.education-item {
  display: flex;
  gap: 1rem;  /* Reduced from 1.2rem */
  margin-bottom: 1rem;  /* Reduced from 1.5rem */
}

.edu-details h4 {
  font-size: 1.1rem;
  margin-bottom: 0.2rem;  /* Reduced from 0.3rem */
  color: #2c3e50;
}

.edu-details p {
  font-size: 1rem;
  color: #718096;
  margin: 0;  /* Remove any default margins */
}

@media (max-width: 768px) {
  .profile-container {
    grid-template-columns: 1fr;
  }
  
  .sections-container {
    flex-direction: column;
  }
  
  .interests-section,
  .education-section {
    min-width: 100%;
  }
}

/* Add to your existing CSS */
.institution-info a {
  font-size: 1.2rem;
  color: #718096;
  text-decoration: none;
  transition: color 0.3s ease;
  display: block;
  margin-top: 0.5rem;
}

.institution-info a:hover {
  color: #2b6cb0;
  text-decoration: underline;
}

/* Ensure the parent container is properly centered */
.profile-info {
  text-align: center;
}

.institution-info {
  margin: 0 auto;
}